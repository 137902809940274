import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { Typography } from "@/uikit/Typography";

import {
  actionsWrapperStyle,
  btnStyle,
  itemWrapperStyle,
  itemsWrapperStyle,
  textStyle,
  titleStyle,
} from "./infoBox.css";

import { ThemeLink } from "@/blocks/tracking";
import { InfoItemSchema } from "@/schema";
import { RemoteImage } from "@/uikit/remote-image";
import { FlexContainer } from "@/uikit/container";

const InfoItem = ({ image, title, text, actions }: InfoItemSchema) => {
  return (
    <div className={itemWrapperStyle}>
      {image && (
        <div>
          <RemoteImage
            sizes={{
              mobile: "calc(100vw - 20px)",
              tablet: "calc(30vw - 20px)",
              desktop: "calc(25vw - 20px)",
            }}
            image={image}
          />
        </div>
      )}
      <Typography className={titleStyle} settings={{ tag: "p" }}>
        {title}
      </Typography>
      <Typography className={textStyle} settings={{ tag: "p" }}>
        {text}
      </Typography>
      <FlexContainer justifyContent="center" alignItems="center" className={actionsWrapperStyle}>
        {actions?.map(
          ({ link, text }) =>
            link && (
              <ThemeLink key={link.href} to={link}>
                <Typography className={btnStyle}>{text}</Typography>
              </ThemeLink>
            ),
        )}
      </FlexContainer>
    </div>
  );
};

const InfoBoxBlock = Block("infoBoxBlock", (props) => {
  const { infoList } = props;

  return (
    <BlockContainer defaultTheme="light" settings={props.settings} blockPaddingTop="large" blockPaddingBottom="large">
      <FlexContainer className={itemsWrapperStyle} alignItems="start" justifyContent="between">
        {infoList.map((infoItem, i) => (
          <InfoItem key={i} {...infoItem} />
        ))}
      </FlexContainer>
    </BlockContainer>
  );
});

export default InfoBoxBlock;
